<template>
  <div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSaveHeader"
            icon="pi pi-save"
            class="mr-2"
            label="Simpan"
            type="submit"
            :disabled="form.obat.length === 0"
          />
          <Button
            :loading="isLoadingBarang"
            :disabled="old_status !== 'draft'"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Barang"
            @click="add"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'SalesReturn' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="formgrid grid">
              <div class="field col-12 md:col-4">
                <label>Tanggal</label>
                <Calendar
                  v-model="form.sr_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>No Retur</label>
                <InputText v-model="form.sr_no" class="w-full" disabled />
              </div>
              <div class="field col-12 md:col-4">
                <label>Status</label>
                <Dropdown
                  v-model="form.status"
                  :options="list_status"
                  :disabled="form.id === 0 || old_status === 'sent'"
                  class="w-full"
                  input-class="w-full"
                />
              </div>
              <div class="field col-6">
                <label
                  :class="{
                    'p-error': v$.form.pelanggan.$invalid && submitted,
                  }"
                  >Pelanggan</label
                >
                <AutoComplete
                  v-model="form.pelanggan"
                  :suggestions="acPelanggan"
                  :min-length="2"
                  :class="{
                    'p-invalid': v$.form.pelanggan.$invalid && submitted,
                  }"
                  class="w-full"
                  input-class="w-full"
                  field="nama"
                  @complete="searchPelanggan($event)"
                  @item-select="selectPelanggan"
                >
                  <template #item="slotProps">
                    <div>
                      <strong>
                        {{ slotProps.item.nama }}
                      </strong>
                    </div>
                    <div>Alamat: {{ slotProps.item.alamat }}</div>
                    <div>Salesman: {{ slotProps.item.salesman.nama }}</div>
                  </template>
                </AutoComplete>
                <small
                  v-if="
                    (v$.form.pelanggan.$invalid && submitted) ||
                    v$.form.pelanggan.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.pelanggan.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-4">
                <label>Salesman</label>
                <Dropdown
                  v-model="form.salesman_id"
                  :options="salesmanList"
                  optionValue="id"
                  optionLabel="nama"
                  :class="{
                    'p-invalid': v$.form.salesman_id.$invalid && submitted,
                  }"
                  class="w-full"
                  @change="handleDropdownSalesmanChange"
                />
                <small
                  v-if="
                    (v$.form.salesman_id.$invalid && submitted) ||
                    v$.form.salesman_id.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.salesman_id.required.$message }}</small
                >
              </div>
              <div class="field col-2">
                <label>Kode</label>
                <InputText
                  v-model="form.salesman_kode"
                  class="w-full"
                  readonly
                  disabled
                />
              </div>
              <!-- <div class="field col-12 md:col-6">
                <label>Jenis Harga</label>
                <Dropdown
                  v-model="form.jenis_harga"
                  :options="list_jenis_harga"
                  optionValue="value"
                  optionLabel="label"
                  class="w-full"
                />
              </div> -->
              <div class="field col-12">
                <label>Keterangan</label>
                <InputText v-model="form.keterangan" class="w-full" />
              </div>
            </div>
          </div>
        </div>
        <grid-retur-penjualan-detail
          :items="form.obat"
          :loading="isLoading"
          :show-button="true"
          :cn="form.cn"
          :dn="form.dn"
          :status="form.status"
          @edit="onEditData"
          @delete="onConfirmDeletion"
          @cn="onUpdateCn"
          @dn="onUpdateDn"
        />
        <!-- @diskon="onUpdateDiskon"
          :diskon="form.diskon_tambahan"
          :nilai-pph="form.pph"
        @pph="onUpdatePPH" -->
      </div>
    </form>
    <Dialog
      header="Tambah Barang"
      v-model:visible="dialogAdd"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
      maximizable
    >
      <form-retur-penjualan-barang
        :sales-invoice="list_obat"
        :exclude-list="list_produk"
        @close="onCloseFormBarang"
        @save="onSaveBarang"
      />
    </Dialog>
    <Dialog
      header="Hapus item dari faktur ?"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <div>
        <span
          >Item <strong>{{ item.nama_obat }}</strong> akan dihapus dari daftar
          retur. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogHapus = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeletePO"
        />
      </template>
    </Dialog>
    <Dialog
      header="Edit barang retur"
      v-model:visible="dialogEdit"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      modal
    >
      <form-barang-retur :item="formEdit" @save="onUpdateBarang" />
    </Dialog>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import errorHandler from '@/helpers/error-handler'
import FormReturPenjualanBarang from '@/components/penjualan/FormReturPenjualanBarang'
import GridReturPenjualanDetail from '@/components/penjualan/GridReturPenjualanDetail'
import ReturPenjualanService from '@/services/ReturPenjualanService'
import SalesInvoiceService from '@/services/SalesInvoiceService'
import PelangganService from '@/services/PelangganService'
import FormBarangRetur from '@/components/penjualan/FormBarangRetur'
import Hotkey from '@/components/Hotkey'
import SalesmanService from '@/services/SalesmanService'
import SettingService from '@/services/SettingService'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    GridReturPenjualanDetail,
    FormReturPenjualanBarang,
    FormBarangRetur,
    Hotkey,
  },
  created() {
    this.pelangganService = new PelangganService()
    this.returPenjualanService = new ReturPenjualanService()
    this.salesInvoiceService = new SalesInvoiceService()
    
    const settingService = new SettingService()
    settingService.get('?filter[key]=RETURN_LIMIT').then((res) => {
      this.return_limit = res.data.data.value ? (res.data.data.value | 0) : 3
    })
  },
  data() {
    return {
      return_limit: 3,
      submitted: false,
      dialogAdd: false,
      dialogHapus: false,
      dialogEdit: false,
      isLoading: false,
      isLoadingBarang: false,
      isLoadingSaveHeader: false,
      acPelanggan: [],
      pelangganService: null,
      salesInvoiceService: null,
      item: null,
      deleted: [],
      list_obat: null,
      form: {
        id: 0,
        sr_at: new Date(),
        sr_no: null,
        status: 'draft',
        // pi_id: null,
        // jenis_harga: 2,
        pelanggan_id: 0,
        keterangan: '',
        salesman_nama: '',
        salesman_kode: '',
        obat: [],
        cn: 0,
        dn: 0,
        // diskon_tambahan: 0,
        // pph: 0,
      },
      /* list_jenis_harga: [
          { value: 1, label: 'Termasuk Pajak' },
          { value: 2, label: 'Belum Termasuk Pajak' },
          { value: 3, label: 'Non Pajak' },
        ], */
      formEdit: {},
      old_status: 'draft',
      list_status: null,
      salesmanList: [],
    }
  },
  async mounted() {
    const salesmanService = new SalesmanService()
    await salesmanService
      .get()
      .then((res) => {
        this.salesmanList = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data area', this)
      })

    if (this.id > 0) {
      this.isLoading = true
      this.returPenjualanService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.form.sr_at = this.form.sr_at ? new Date(this.form.sr_at) : null
            this.form.pelanggan = res.data.data.pelanggan
            this.form.salesman = res.data.data.salesman
            this.form.salesman_nama = res.data.data.salesman.nama
            this.form.salesman_kode = res.data.data.salesman.kode
            this.old_status = this.form.status
            this.getListStatus()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Sales Return', this)
        })
        .finally(() => (this.isLoading = false))
    }
    this.getListStatus()
  },
  computed: {
    list_produk() {
      return this.form.obat.map((x) => {
        const item = {}
        item.obat_id = x.obat_id
        item.si_no = x.si_no
        item.batch_id = x.batch_id
        return item
      })
    },
  },
  methods: {
    getListStatus() {
      let status = []
      switch (this.old_status) {
        case 'draft':
          status = ['draft', 'sent']
          break
        case 'sent':
          status = ['sent']
          break
        default:
          status.push('draft')
          break
      }
      this.list_status = status
    },
    async getSupplier() {
      const pelangganService = new PelangganService()
      return await pelangganService
        .get('?sort=nama')
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Pelanggan', this)
        })
    },
    add() {
      if (!this.form.pelanggan) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Retur penjualan',
          detail: 'Pelanggan harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }
      if (!this.form.salesman_id) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Retur penjualan',
          detail: 'Salesman harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }

      // Get the current date
      const currentDate = new Date();

      // Get the date three months ago (first day of that month)
      const firstDayThreeMonthsAgo = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth() - this.return_limit, 1));

      // Format the dates in YYYY-MM-DD format
      const formattedCurrentDate = currentDate.toISOString().slice(0, 10);
      const formattedThreeMonthsAgo = firstDayThreeMonthsAgo.toISOString().slice(0, 10);

      const self = this
      self.isLoadingBarang = true
      this.salesInvoiceService
        .get(
          `?filter[pelanggan]=${this.form.pelanggan.nama}&filter[status]=done&filter[order_between]=${formattedThreeMonthsAgo},${formattedCurrentDate}`
        )
        .then((res) => {
          if (!res.data.data || res.data.data.length === 0) {
            this.$toast.add({
              severity: 'warn',
              summary: 'Sales Order',
              detail: `Faktur penjualan untuk pelanggan ${this.form.pelanggan.nama} tidak ditemukan.`,
              life: 3000,
            })
            self.isLoadingBarang = false
            return
          } else {
            self.list_obat = res.data.data
            self.dialogAdd = true
            self.isLoadingBarang = false
          }
        })
        .catch(() => {
          self.isLoadingBarang = false
        })
    },
    onEditData(item) {
      this.formEdit = Object.assign({}, item)
      this.dialogEdit = true
    },
    onConfirmDeletion(item) {
      this.item = Object.assign({}, item)
      this.dialogHapus = true
    },
    onDeletePO() {
      if (this.item.id !== 0) {
        this.deleted.push(this.item.id)
      }
      const index = this.form.obat.findIndex(
        (el) => el.id === this.item.id && el.obat_id === this.item.obat_id && el.batch_id === this.item.batch_id
      )
      this.form.obat.splice(index, 1)
      this.item = {}
      this.dialogHapus = false
    },
    save(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      let form = {}

      let obat = []
      this.form.obat.forEach((el) => {
        if (el.id > 0) {
          obat.push({
            id: el.id,
            batch_id: el.batch_id,
            /*keterangan: !el.keterangan ? '' : el.keterangan,*/
            quantity: el.quantity,
          })
        } else {
          obat.push({
            id: 0,
            sid_id: el.sid_id,
            batch_id: el.batch_id,
            /*keterangan: !el.keterangan ? '' : el.keterangan,*/
            quantity: el.quantity,
          })
        }
      })

      form.id = this.form.id
      form.sr_at = this.form.sr_at
        ? dayjs(this.form.sr_at).format('YYYY-MM-DD HH:mm:ss')
        : null

      form.obat = obat
      form.status = this.form.status
      form.sr_no = ''

      if (form.id === 0) delete form.id

      if (this.form.sr_no) {
        form.sr_no = this.form.sr_no
      }
      if (this.form.pelanggan) {
        form.pelanggan_id = this.form.pelanggan.id
      }
      if (this.form.salesman_id) {
        form.salesman_id = this.form.salesman_id
      }
      if (this.form.keterangan) {
        form.keterangan = this.form.keterangan
      }

      form.cn = this.form.cn
      form.dn = this.form.dn

      if (form.obat.length > 0) {
        if (this.form.id === 0) {
          this.isLoadingSaveHeader = true
          this.returPenjualanService
            .add(form)
            .then((res) => {
              if (res.data.status === 200) {
                this.$toast.add({
                  severity: 'success',
                  summary: 'Retur penjualan',
                  detail: 'Data berhasil disimpan',
                  life: 3000,
                })
                this.$router.push({ name: 'SalesReturn' })
              }
            })
            .catch((err) => {
              errorHandler(err, 'Retur penjualan', this)
            })
            .finally(() => {
              this.isLoadingSaveHeader = false
              this.refreshDetail()
            })
        } else {
          this.isLoadingSaveHeader = true
          form.deleted = this.deleted
          this.returPenjualanService
            .update(form)
            .then((res) => {
              if (res.data.status === 200) {
                this.$toast.add({
                  severity: 'success',
                  summary: 'Retur penjualan',
                  detail: 'Data berhasil disimpan.',
                  life: 3000,
                })
              }
            })
            .catch((err) => {
              errorHandler(err, 'Retur penjualan', this)
            })
            .finally(() => {
              this.deleted = []
              this.isLoadingSaveHeader = false
              this.refreshDetail()
            })
        }
      }
    },
    refreshDetail() {
      if (this.form.id > 0) {
        this.returPenjualanService
          .get(`/${this.id}`)
          .then((res) => {
            if (res.data.status === 200) {
              this.form.obat = res.data.data.obat
            }
          })
          .catch((err) => {
            errorHandler(err, 'Retur penjualan (Obat)', this)
          })
      }
    },
    onSaveBarang(item) {
      item.forEach((el) => {
        const batch = el.batch
        batch.forEach((b) => {
          let newEl = { ...el }
          delete newEl.batch
          newEl.batch_id = b.batch_id
          newEl.batch_no = b.batch_no
          newEl.expired_at = new Date(b.expired_at)
          newEl.quantity = b.quantity
          let harga_diskon = 0
          if (newEl.harga_diskon) {
            harga_diskon = newEl.harga_diskon
          } else {
            const diskon_persentase = newEl.diskon
              ? ((newEl.harga * newEl.diskon) / 100) * newEl.quantity
              : 0
            const diskon_rp =
              newEl.diskon_harga > 0 ? newEl.diskon_harga * newEl.quantity : 0
            harga_diskon = newEl.diskon > 0 ? diskon_persentase : diskon_rp
          }
          newEl.dpp = harga_diskon * newEl.quantity
          //newEl.harga_total = (harga_diskon + ((newEl.ppn * harga_diskon) / 100)) * newEl.quantity
          newEl.harga_total = newEl.quantity * newEl.harga
          const index = this.form.obat.findIndex(
            (old) =>
              old.obat_id === newEl.obat_id &&
              old.sid_id === newEl.sid_id &&
              old.batch_id === newEl.batch_id
          )
          if (index === -1) {
            this.form.obat.push(newEl)
          }
        })
        //this.form.obat.push(el)
      })
      this.dialogAdd = false
    },
    onUpdateBarang(item) {
      const index = this.form.obat.findIndex(
        (el) =>
          el.id === item.id &&
          el.obat_id === item.obat_id &&
          el.sid_id === item.sid_id &&
          el.batch_id === item.batch_id
      )
      this.form.obat[index] = item
      this.dialogEdit = false
    },
    onCloseFormBarang() {
      this.dialogAdd = false
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
    searchPelanggan(e) {
      this.pelangganService
        .get('?filter[nama]=' + e.query + '&filter[status]=aktif')
        .then((res) => {
          this.acPelanggan = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Pelanggan', this)
        })
    },
    selectPelanggan(e) {
      this.form.pelanggan = e.value
      this.form.pelanggan_id = e.value.id
      this.form.salesman_id = e.value.salesman.id
      this.form.salesman_nama = e.value.salesman.nama
      this.form.salesman_kode = e.value.salesman.kode
    },
    handleDropdownSalesmanChange() {
      for (let index = 0; index < this.salesmanList.length; index++) {
        const salesman = this.salesmanList[index]

        if (salesman.id === this.form.salesman_id) {
          this.form.salesman_kode = salesman.kode
          break
        }
      }
    },
    onUpdateCn(item) {
      this.form.cn = item
    },
    onUpdateDn(item) {
      this.form.dn = item
    },
    /* onUpdateDiskon(item) {
        this.form.diskon_tambahan = item
      },
      onUpdatePPH(item) {
        this.form.pph = item
      }, */
  },
  validations() {
    return {
      form: {
        pelanggan: {
          required: helpers.withMessage('Pelanggan harus diisi.', required),
        },
        salesman_id: {
          required: helpers.withMessage('Salesman harus diisi.', required),
        },
      },
    }
  },
}
</script>
